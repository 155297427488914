@import 'customizeBootstrap';

// Additional Bootstrap spacing classes

// xx-1 = 4px
// xx-2 = 8px
// xx-3 = 16px
// xx-4 = 24px
// xx-4-5= 32px
// xx-5 = 48px
// xx-6 = 64px
// xx-7 = 96px


// ---------------------------- custom p-4-5 padding units ----------------------------
@media (max-width: 575px) {
    .p-4-5 {
        padding: $spacer*2 !important;
    }

    .pt-4-5 {
        padding-top: $spacer*2 !important;
    }

    .pb-4-5 {
        padding-bottom: $spacer*2 !important;
    }

    .py-4-5 {
        padding-top: $spacer*2 !important;
        padding-bottom: $spacer*2 !important;
    }

    .px-4-5 {
        padding-left: $spacer*2 !important;
        padding-right: $spacer*2 !important;
    }

    .pl-4-5 {
        padding-left: $spacer*2 !important;
    }

    .pr-4-5 {
        padding-right: $spacer*2 !important;
    }

    // custom margin units
    .m-4-5 {
        margin: $spacer*2 !important;
    }

    .mt-4-5 {
        margin-top: $spacer*2 !important;
    }

    .mb-4-5 {
        margin-bottom: $spacer*2 !important;
    }

    .my-4-5 {
        margin-top: $spacer*2 !important;
        margin-bottom: $spacer*2 !important;
    }

    .mx-4-5 {
        margin-left: $spacer*2 !important;
        margin-right: $spacer*2 !important;
    }

    .ml-4-5 {
        margin-left: $spacer*2 !important;
    }

    .mr-4-5 {
        margin-right: $spacer*2 !important;
    }


    // ---------------------------- custom p-6 padding units ----------------------------
    .p-6 {
        padding: $spacer*4 !important;
    }

    .pt-6 {
        padding-top: $spacer*4 !important;
    }

    .pb-6 {
        padding-bottom: $spacer*4 !important;
    }

    .py-6 {
        padding-top: $spacer*4 !important;
        padding-bottom: $spacer*4 !important;
    }

    .px-6 {
        padding-left: $spacer*4 !important;
        padding-right: $spacer*4 !important;
    }

    .pl-6 {
        padding-left: $spacer*4 !important;
    }

    .pr-6 {
        padding-right: $spacer*4 !important;
    }

    // custom margin units
    .m-6 {
        margin: $spacer*4 !important;
    }

    .mt-6 {
        margin-top: $spacer*4 !important;
    }

    .mb-6 {
        margin-bottom: $spacer*4 !important;
    }

    .my-6 {
        margin-top: $spacer*4 !important;
        margin-bottom: $spacer*4 !important;
    }

    .mx-6 {
        margin-left: $spacer*4 !important;
        margin-right: $spacer*4 !important;
    }

    .ml-6 {
        margin-left: $spacer*4 !important;
    }

    .mr-6 {
        margin-right: $spacer*4 !important;
    }


    // ---------------------------- custom p-7 padding units  ----------------------------
    .p-7 {
        padding: $spacer*6 !important;
    }

    .pt-7 {
        padding-top: $spacer*6 !important;
    }

    .pb-7 {
        padding-bottom: $spacer*6 !important;
    }

    .py-7 {
        padding-top: $spacer*6 !important;
        padding-bottom: $spacer*6 !important;
    }

    .px-7 {
        padding-left: $spacer*6 !important;
        padding-right: $spacer*6 !important;
    }

    .pl-7 {
        padding-left: $spacer*6 !important;
    }

    .pr-7 {
        padding-right: $spacer*6 !important;
    }

    // custom margin units
    .m-7 {
        margin: $spacer*6 !important;
    }

    .mt-7 {
        margin-top: $spacer*6 !important;
    }

    .mb-7 {
        margin-bottom: $spacer*6 !important;
    }

    .my-7 {
        margin-top: $spacer*6 !important;
        margin-bottom: $spacer*6 !important;
    }

    .mx-7 {
        margin-left: $spacer*6 !important;
        margin-right: $spacer*6 !important;
    }

    .ml-7 {
        margin-left: $spacer*6 !important;
    }

    .mr-7 {
        margin-right: $spacer*6 !important;
    }
}

@media (min-width: 576px) and (max-width: 767px){

    // ---------------------------- custom p-sm-4-5 (576) padding  units ----------------------------
    .p-sm-4-5 {
        padding: $spacer*2 !important;
    }

    .pt-sm-4-5 {
        padding-top: $spacer*2 !important;
    }

    .pb-sm-4-5 {
        padding-bottom: $spacer*2 !important;
    }

    .py-sm-4-5 {
        padding-top: $spacer*2 !important;
        padding-bottom: $spacer*2 !important;
    }

    .px-sm-4-5 {
        padding-left: $spacer*2 !important;
        padding-right: $spacer*2 !important;
    }

    .pl-sm-4-5 {
        padding-left: $spacer*2 !important;
    }

    .pr-sm-4-5 {
        padding-right: $spacer*2 !important;
    }

    // custom sm (576) margin units
    .m-sm-4-5 {
        margin: $spacer*2 !important;
    }

    .mt-sm-4-5 {
        margin-top: $spacer*2 !important;
    }

    .mb-sm-4-5 {
        margin-bottom: $spacer*2 !important;
    }

    .my-sm-4-5 {
        margin-top: $spacer*2 !important;
        margin-bottom: $spacer*2 !important;
    }

    .mx-sm-4-5 {
        margin-left: $spacer*2 !important;
        margin-right: $spacer*2 !important;
    }

    .ml-sm-4-5 {
        margin-left: $spacer*2 !important;
    }

    .mr-sm-4-5 {
        margin-right: $spacer*2 !important;
    }

    // ---------------------------- custom p-sm-6 (576) padding  units ----------------------------
    .p-sm-6 {
        padding: $spacer*4 !important;
    }

    .pt-sm-6 {
        padding-top: $spacer*4 !important;
    }

    .pb-sm-6 {
        padding-bottom: $spacer*4 !important;
    }

    .py-sm-6 {
        padding-top: $spacer*4 !important;
        padding-bottom: $spacer*4 !important;
    }

    .px-sm-6 {
        padding-left: $spacer*4 !important;
        padding-right: $spacer*4 !important;
    }

    .pl-sm-6 {
        padding-left: $spacer*4 !important;
    }

    .pr-sm-6 {
        padding-right: $spacer*4 !important;
    }

    // custom sm (576) margin units
    .m-sm-6 {
        margin: $spacer*4 !important;
    }

    .mt-sm-6 {
        margin-top: $spacer*4 !important;
    }

    .mb-sm-6 {
        margin-bottom: $spacer*4 !important;
    }

    .my-sm-6 {
        margin-top: $spacer*4 !important;
        margin-bottom: $spacer*4 !important;
    }

    .mx-sm-6 {
        margin-left: $spacer*4 !important;
        margin-right: $spacer*4 !important;
    }

    .ml-sm-6 {
        margin-left: $spacer*4 !important;
    }

    .mr-sm-6 {
        margin-right: $spacer*4 !important;
    }

    // ---------------------------- custom p-sm-7 (576) padding  units ----------------------------
    .p-sm-7 {
        padding: $spacer*6 !important;
    }

    .pt-sm-7 {
        padding-top: $spacer*6 !important;
    }

    .pb-sm-7 {
        padding-bottom: $spacer*6 !important;
    }

    .py-sm-7 {
        padding-top: $spacer*6 !important;
        padding-bottom: $spacer*6 !important;
    }

    .px-sm-7 {
        padding-left: $spacer*6 !important;
        padding-right: $spacer*6 !important;
    }

    .pl-sm-7 {
        padding-left: $spacer*6 !important;
    }

    .pr-sm-7 {
        padding-right: $spacer*6 !important;
    }

    // custom sm (576) margin units
    .m-sm-7 {
        margin: $spacer*6 !important;
    }

    .mt-sm-7 {
        margin-top: $spacer*6 !important;
    }

    .mb-sm-7 {
        margin-bottom: $spacer*6 !important;
    }

    .my-sm-7 {
        margin-top: $spacer*6 !important;
        margin-bottom: $spacer*6 !important;
    }

    .mx-sm-7 {
        margin-left: $spacer*6 !important;
        margin-right: $spacer*6 !important;
    }

    .ml-sm-7 {
        margin-left: $spacer*6 !important;
    }

    .mr-sm-7 {
        margin-right: $spacer*6 !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    // ---------------------------- custom p-md-4-5 (768) padding  units ----------------------------
    .p-md-4-5 {
        padding: $spacer*2 !important;
    }

    .pt-md-4-5 {
        padding-top: $spacer*2 !important;
    }

    .pb-md-4-5 {
        padding-bottom: $spacer*2 !important;
    }

    .py-md-4-5 {
        padding-top: $spacer*2 !important;
        padding-bottom: $spacer*2 !important;
    }

    .px-md-4-5 {
        padding-left: $spacer*2 !important;
        padding-right: $spacer*2 !important;
    }

    .pl-md-4-5 {
        padding-left: $spacer*2 !important;
    }

    .pr-md-4-5 {
        padding-right: $spacer*2 !important;
    }

    // custom md (768) margin units
    .m-md-4-5 {
        margin: $spacer*2 !important;
    }

    .mt-md-4-5 {
        margin-top: $spacer*2 !important;
    }

    .mb-md-4-5 {
        margin-bottom: $spacer*2 !important;
    }

    .my-md-4-5 {
        margin-top: $spacer*2 !important;
        margin-bottom: $spacer*2 !important;
    }

    .mx-md-4-5 {
        margin-left: $spacer*2 !important;
        margin-right: $spacer*2 !important;
    }

    .ml-md-4-5 {
        margin-left: $spacer*2 !important;
    }

    .mr-md-4-5 {
        margin-right: $spacer*2 !important;
    }

    // ---------------------------- custom pt-md-6 (768) padding  units ----------------------------
    .p-md-6 {
        padding: $spacer*4 !important;
    }

    .pt-md-6 {
        padding-top: $spacer*4 !important;
    }

    .pb-md-6 {
        padding-bottom: $spacer*4 !important;
    }

    .py-md-6 {
        padding-top: $spacer*4 !important;
        padding-bottom: $spacer*4 !important;
    }

    .px-md-6 {
        padding-left: $spacer*4 !important;
        padding-right: $spacer*4 !important;
    }

    .pl-md-6 {
        padding-left: $spacer*4 !important;
    }

    .pr-md-6 {
        padding-right: $spacer*4 !important;
    }

    // custom md (768) margin units
    .m-md-6 {
        margin: $spacer*4 !important;
    }

    .mt-md-6 {
        margin-top: $spacer*4 !important;
    }

    .mb-md-6 {
        margin-bottom: $spacer*4 !important;
    }

    .my-md-6 {
        margin-top: $spacer*4 !important;
        margin-bottom: $spacer*4 !important;
    }

    .mx-md-6 {
        margin-left: $spacer*4 !important;
        margin-right: $spacer*4 !important;
    }

    .ml-md-6 {
        margin-left: $spacer*4 !important;
    }

    .mr-md-6 {
        margin-right: $spacer*4 !important;
    }

    // ---------------------------- custom pt-md-7 (768) padding  units ----------------------------
    .p-md-7 {
        padding: $spacer*6 !important;
    }

    .pt-md-7 {
        padding-top: $spacer*6 !important;
    }

    .pb-md-7 {
        padding-bottom: $spacer*6 !important;
    }

    .py-md-7 {
        padding-top: $spacer*6 !important;
        padding-bottom: $spacer*6 !important;
    }

    .px-md-7 {
        padding-left: $spacer*6 !important;
        padding-right: $spacer*6 !important;
    }

    .pl-md-7 {
        padding-left: $spacer*6 !important;
    }

    .pr-md-7 {
        padding-right: $spacer*6 !important;
    }

    // custom md (768) margin units
    .m-md-6 {
        margin: $spacer*6 !important;
    }

    .mt-md-7 {
        margin-top: $spacer*6 !important;
    }

    .mb-md-7 {
        margin-bottom: $spacer*6 !important;
    }

    .my-md-7 {
        margin-top: $spacer*6 !important;
        margin-bottom: $spacer*6 !important;
    }

    .mx-md-7 {
        margin-left: $spacer*6 !important;
        margin-right: $spacer*6 !important;
    }

    .ml-md-7 {
        margin-left: $spacer*6 !important;
    }

    .mr-md-7 {
        margin-right: $spacer*6 !important;
    }

}

@media (min-width: 992px) {

    // ---------------------------- custom pt-lg-4-5 (992) padding  units ----------------------------
    .p-lg-4-5 {
        padding: $spacer*2 !important;
    }

    .pt-lg-4-5 {
        padding-top: $spacer*2 !important;
    }

    .pb-lg-4-5 {
        padding-bottom: $spacer*2 !important;
    }

    .py-lg-4-5 {
        padding-top: $spacer*2 !important;
        padding-bottom: $spacer*2 !important;
    }

    .px-lg-4-5 {
        padding-left: $spacer*2 !important;
        padding-right: $spacer*2 !important;
    }

    .pl-lg-4-5 {
        padding-left: $spacer*2 !important;
    }

    .pr-lg-4-5 {
        padding-right: $spacer*2 !important;
    }

    // custom lg (992) margin units
    .m-lg-4-5 {
        margin: $spacer*2 !important;
    }

    .mt-lg-4-5 {
        margin-top: $spacer*2 !important;
    }

    .mb-lg-4-5 {
        margin-bottom: $spacer*2 !important;
    }

    .my-lg-4-5 {
        margin-top: $spacer*2 !important;
        margin-bottom: $spacer*2 !important;
    }

    .mx-lg-4-5 {
        margin-left: $spacer*2 !important;
        margin-right: $spacer*2 !important;
    }

    .ml-lg-4-5 {
        margin-left: $spacer*2 !important;
    }

    .mr-lg-4-5 {
        margin-right: $spacer*2 !important;
    }

    // ---------------------------- custom pt-lg-6 (992) padding  units ----------------------------
    .p-lg-6 {
        padding: $spacer*4 !important;
    }

    .pt-lg-6 {
        padding-top: $spacer*4 !important;
    }

    .pb-lg-6 {
        padding-bottom: $spacer*4 !important;
    }

    .py-lg-6 {
        padding-top: $spacer*4 !important;
        padding-bottom: $spacer*4 !important;
    }

    .px-lg-6 {
        padding-left: $spacer*4 !important;
        padding-right: $spacer*4 !important;
    }

    .pl-lg-6 {
        padding-left: $spacer*4 !important;
    }

    .pr-lg-6 {
        padding-right: $spacer*4 !important;
    }

    // custom lg (992) margin units
    .m-lg-6 {
        margin: $spacer*4 !important;
    }

    .mt-lg-6 {
        margin-top: $spacer*4 !important;
    }

    .mb-lg-6 {
        margin-bottom: $spacer*4 !important;
    }

    .my-lg-6 {
        margin-top: $spacer*4 !important;
        margin-bottom: $spacer*4 !important;
    }

    .mx-lg-6 {
        margin-left: $spacer*4 !important;
        margin-right: $spacer*4 !important;
    }

    .ml-lg-6 {
        margin-left: $spacer*4 !important;
    }

    .mr-lg-6 {
        margin-right: $spacer*4 !important;
    }

    // ---------------------------- custom pt-lg-7 (992) padding  units ----------------------------
    .p-lg-7 {
        padding: $spacer*6 !important;
    }

    .pt-lg-7 {
        padding-top: $spacer*6 !important;
    }

    .pb-lg-7 {
        padding-bottom: $spacer*6 !important;
    }

    .py-lg-7 {
        padding-top: $spacer*6 !important;
        padding-bottom: $spacer*6 !important;
    }

    .px-lg-7 {
        padding-left: $spacer*6 !important;
        padding-right: $spacer*6 !important;
    }

    .pl-lg-7 {
        padding-left: $spacer*6 !important;
    }

    .pr-lg-7 {
        padding-right: $spacer*6 !important;
    }

    // custom lg (992) margin units
    .m-lg-7 {
        margin: $spacer*6 !important;
    }

    .mt-lg-7 {
        margin-top: $spacer*6 !important;
    }

    .mb-lg-7 {
        margin-bottom: $spacer*6 !important;
    }

    .my-lg-7 {
        margin-top: $spacer*6 !important;
        margin-bottom: $spacer*6 !important;
    }

    .mx-lg-7 {
        margin-left: $spacer*6 !important;
        margin-right: $spacer*6 !important;
    }

    .ml-lg-7 {
        margin-left: $spacer*6 !important;
    }

    .mr-lg-7 {
        margin-right: $spacer*6 !important;
    }
}
