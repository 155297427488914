@import 'scss/customizeBootstrap';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'scss/mixins';
@import 'scss/colors';
@import 'scss/fonts';
@import 'scss/links';
@import 'scss/layout';
@import 'scss/sizes';
@import 'scss/customSpacing';
@import "scss/glyph-icons";



$TITLE_COLOR: $COLOR_PRIMARY_BLACK;
$BACKGROUND_HIGHLIGHTING: $COLOR_PRIMARY_SKY;
$INFO_BOX_BORDER: $COLOR_PRIMARY_SKY_MEDIUM;
$OPENING_TIMES: $COLOR_PRIMARY_GRAPHITE;
$PHONE_NUMBER: $COLOR_PRIMARY_BLACK;
$CURRENT_DEAL_BOX_BACKGROUND: $COLOR_PRIMARY_SKY;

@font-face {
    font-family: "Santander Text";
    src: url(assets/fonts/Santander/SantanderText-Regular.otf) format("otf"),
    url(assets/fonts/Santander/SantanderTextW05-Regular.woff) format("woff"),
    url(assets/fonts/Santander/SantanderTextW05-Regular.eot) format("eot"),
    url(assets/fonts/Santander/SantanderTextW05-Regular.ttf) format("ttf"),
    url(assets/fonts/Santander/SantanderTextW05-Regular.woff2) format("woff2");
}

@font-face {
    font-family: "Santander Headline";
    src: url(assets/fonts/Santander/SantanderHeadline-Regular.otf) format("otf"),
    url(assets/fonts/Santander/SantanderHeadlineW05-Rg.woff) format("woff"),
    url(assets/fonts/Santander/SantanderHeadlineW05-Rg.eot) format("eot"),
    url(assets/fonts/Santander/SantanderHeadlineW05-Rg.ttf) format("ttf"),
    url(assets/fonts/Santander/SantanderHeadlineW05-Rg.woff2) format("woff2");
}

@font-face {
    font-family: "Santander Text Bold";
    src: url(assets/fonts/Santander/SantanderText-Bold.otf) format("otf"),
    url(assets/fonts/Santander/SantanderTextW05-Bold.woff) format("woff"),
    url(assets/fonts/Santander/SantanderTextW05-Bold.eot) format("eot"),
    url(assets/fonts/Santander/SantanderTextW05-Bold.ttf) format("ttf"),
    url(assets/fonts/Santander/SantanderTextW05-Bold.woff2) format("woff2");
}

* {
    font-family: "Santander Text";
}

.grecaptcha-badge, .recaptcha {
    z-index: 99;
    bottom: 15px;
  }

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

//deliberately overriding 1200 media query to fit to brand guidelines.
.container {
    max-width: 1024px;
}

.graphite-text {
    color: $COLOR_PRIMARY_GRAPHITE;
}
.turquoise-text {
    color: $COLOR_SECONDARY_TURQUOISE_DARK;
}

.black-text {
    color: black !important;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    min-height: 100%;
    height: 100vh;
}

.callout {
    padding: .8rem 1.6rem;
    margin-bottom: 1.6rem;
    position: relative;
}

.callout > .border-highlight {
    height: 4.64rem;
    position: absolute;
    top: .56rem;
    width: .4rem;
    left: -2px;
    background-color: #EC0000;
}

.large-responsive-icon {
    @include for-mobile {
        width: 60px;
        height: 60px;
    }
    @include for-tablet {
        width: 90px;
    }
    @include for-tablet-large {
        width: 110px;
    }
    @include for-desktop {
        width: 110px;
    }
}

.responsive-icon {
    @include for-mobile {
        max-width: 36px;
        max-height: 36px;
    }
}

.success-logo-size {
    @include for-mobile {
        height: 50px;
        width: 50px;
    }
    @include for-tablet {
        height: 60px;
        width: 60px;
    }
    @include for-tablet-large {
        height: 70px;
        width: 70px;
    }
    @include for-desktop {
        height: 80px;
        width: 80px;
    }
}

.callout > .border-highlight {
    @include for-tablet {
        top: .8rem;
    }
    @include for-tablet-large {
        top: .8rem;
    }
    @include for-desktop {
        top: .8rem;
    }
}

.no-border {
    border: 0px transparent !important;
    //border-radius: 0px;
}

.blue-border {
    border-radius: 5px;
    border: 1px solid $INFO_BOX_BORDER;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
}

.blue-background {
    background: linear-gradient(180deg, $BACKGROUND_HIGHLIGHTING 500px, white 0%);
}

.opening-times {
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
    color: $OPENING_TIMES;
}

.phone-number {
    font-size: 2.4rem;
    line-height: 1.33;
    text-align: center;
    color: $PHONE_NUMBER;
}

.full-width-hr {
    border-top: 1px solid $COLOR_SECONDARY_LIGHT_BLUE;
}

.accordion {
    border-bottom: solid 1px $COLOR_SECONDARY_LIGHT_BLUE;
    @include for-tablet {
        border: solid 1px $COLOR_SECONDARY_LIGHT_BLUE;
        border-radius: 4px;
    }
}
.accordion-selected {
    border-bottom: solid 1px $COLOR_SECONDARY_LIGHT_BLUE;
    border-color: $COLOR_SECONDARY_LIGHT_BLUE;
    background-color: $COLOR_SECONDARY_LIGHT_BLUE_SELECTED;
    @include for-tablet {
        border: solid 1px $COLOR_SECONDARY_LIGHT_BLUE;
        border-radius: 4px;
    }
}

.horizontal-line {
    color: $CURRENT_DEAL_BOX_BACKGROUND;
}

.chevron-size {
    width: 24px;
    height: 82px;
    object-fit: contain;
    vertical-align: bottom;
}

.production-description {
    @include for-desktop {
        margin-bottom: 2px;
    }
}

.no-background {
    background-color: white;
}

.home-loan-separator {
    @include for-mobile {
        margin: 0.8rem 0;
    }
    @include for-tablet {
        margin: 0.8rem 0;
    }
    @include for-tablet-large {
        margin: 1.6rem 0;
    }
    @include for-desktop {
        margin: 1.6rem 0;
    }

}

input[type="checkbox"] {
    -moz-appearance: none;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
    background: url("/assets/checkbox-unselected.svg");
    width: 24px;
    height: 24px;
}

input[type="checkbox"]:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("/assets/checkbox-selected.svg");
}

input[type="checkbox"]:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    outline: 0 none;
    box-shadow: none;
}

input[type="checkbox"]::-ms-check {
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    display: none;
    background-image: url("/assets/checkbox-unselected.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
}

input[type="checkbox"]:checked::-ms-check {
    border: none;
    display: none;
    background-image: url("/assets/checkbox-selected.svg");
    background-repeat: no-repeat;

}

.backgraound-img {
    background-image: url("/assets/glyph-icon-tick-in-circle-green.svg");
}

input[type="checkbox"]:focus::-ms-check {
    border: none;
    display: none;
    outline: 0 none;
    box-shadow: none;
}

.eligible-loan-box {
    &:hover {
        cursor: pointer;
    }
}

.loan-field-label {
    color: $COLOR_PRIMARY_GRAPHITE;
}

.not-eligible-chevron {
    cursor: pointer;
}
//** overrides **//

@media print {
    .noprint {
        display: none !important;
    }
}

// The ui-switch component that we are using does not allow to change the border of the button
// With this css rule we override the border color only when is checked.
ui-switch {
    .switch {
        -webkit-transition: .25s ease-in-out !important;
        -moz-transition: .25s ease-in-out !important;
        -o-transition: .25s ease-in-out !important;
        transition: .25s ease-in-out !important;
    }

    .switch.checked {
        border-color: $COLOR_SECONDARY_TURQUOISE !important;
    }

    // Fix for toggle ball to go back left on iphone
    .switch.switch-small small {
        right: 12px;
    }
    .switch.checked small {
        right: 0 !important;
    }
}

.corinthian-link {
    cursor: pointer;
    color: #ec0000;
    font-size: 1.5rem;
    font-weight: bold;
}

.label-disabled {
    color: $COLOR_PRIMARY_GREY_DISABLED;
}

.alert-info {
    border-color: $COLOR_SECONDARY_TURQUOISE_10PC;
    background-color: $COLOR_SECONDARY_TURQUOISE_10PC;
    color: $COLOR_PRIMARY_BLACK;
}

.floaty-phone-icon {
    z-index: 1000 !important;
    bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    pointer-events: none !important;
}

