
$COLOR_PRIMARY_BLACK: #000000;
$COLOR_PRIMARY_BLACK_33PC: rgba(0, 0, 0, 0.33);
$COLOR_PRIMARY_BLACK_50PC: rgba(0, 0, 0, 0.5);

$COLOR_PRIMARY_GRAPHITE: #6F7779;
$COLOR_PRIMARY_GRAPHITE_10PC: rgba(111, 119, 121, 0.1);
$COLOR_PRIMARY_GRAPHITE_20PC: rgba(111, 119, 121, 0.2);

$COLOR_PRIMARY_GREY_DISABLED: #CCCCCC;

$COLOR_PRIMARY_RED: #EC0000;
$COLOR_PRIMARY_RED_10PC: rgba(236,0,0,0.1);
$COLOR_PRIMARY_RED_DARK: #990000;
$COLOR_PRIMARY_RED_MEDIUM: #CC0000;

$COLOR_PRIMARY_SILVER: #c2c2c2;

$COLOR_PRIMARY_SKY: #DEEDF2;
$COLOR_PRIMARY_SKY_30_PERCENT_LIGHT: #F6FAFC;
$COLOR_PRIMARY_SKY_50_PERCENT_LIGHT: rgba(222, 237, 242, 0.5);
$COLOR_PRIMARY_SKY_DARK: #9BC3D3;
$COLOR_PRIMARY_SKY_MEDIUM: #C3DEE7;
$COLOR_PRIMARY_SKY_100_PERCENT_DARK: #257fa4;

$COLOR_PRIMARY_WHITE: #FFFFFF;

$COLOR_SECONDARY_BLUE: #3366FF;
$COLOR_SECONDARY_BLUE_DARK: #0032E6;

$COLOR_SECONDARY_GREEN: #63BA68;
$COLOR_SECONDARY_GREEN_10PC: rgba(99, 186, 104, 0.1);
$COLOR_SECONDARY_GREEN_DARK: #3A8340;

$COLOR_SECONDARY_LIGHT_BLUE: #C3DEE7;
$COLOR_SECONDARY_LIGHT_BLUE_SELECTED: rgba(222, 237, 242, 0.5);

$COLOR_SECONDARY_PINK: #fde5e5;

$COLOR_SECONDARY_PURPLE: #9E3667;
$COLOR_SECONDARY_PURPLE_DARK: #732645;

$COLOR_SECONDARY_TURQUOISE: rgba(27, 179, 188, 1);
$COLOR_SECONDARY_TURQUOISE_10PC: #E8F7F8;
$COLOR_SECONDARY_TURQUOISE_20PC: rgba(27, 179, 188, 0.2);
$COLOR_SECONDARY_TURQUOISE_50PC: rgba(27, 179, 188, 0.5);
$COLOR_SECONDARY_TURQUOISE_DARK: #137E84;

$COLOR_SECONDARY_YELLOW: rgba(255, 204, 51, 1);
$COLOR_SECONDARY_YELLOW_10PC: rgba(255, 204, 51, 0.1);
$COLOR_SECONDARY_YELLOW_DARK: #946F00;

$FIRST_TOOLTIP: #137e84;
$FORTH_TOOLTIP: #990000;
$SECOND_TOOLTIP: #732645;
$THIRD_TOOLTIP: #3a8340;
