@import 'colors';
@import 'layout';
@import 'mixins';

html{
    font-size: 62.5%;
}

body {
    font-family: 'Santander Text';
    color: $COLOR_PRIMARY_BLACK ;
    font-size: 1.6rem;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-size-14{
    font-size: 1.4rem;
}

.font-size-16{
    font-size: 1.6rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Santander Headline';
}

h1 {
    @include for-mobile {
        font-size: 2.4rem;
    }
    @include for-tablet {
        font-size: 2.8rem;
    }
    @include for-tablet-large {
        font-size: 3.2rem;
    }
    @include for-desktop {
        font-size: 3.6rem;
    }
}

h2 {
    &.modal-title {
        @include for-mobile {
            font-size: 2rem;
        }
        @include for-tablet {
            font-size: 2.4rem;
        }
        @include for-tablet-large {
            font-size: 2.4rem;
        }
        @include for-desktop {
            font-size: 2.6rem;
        }
    }
    @include for-mobile {
        font-size: 2.2rem;
    }
    @include for-tablet {
        font-size: 2.4rem;
    }
    @include for-tablet-large {
        font-size: 2.6rem;
    }
    @include for-desktop {
        font-size: 2.8rem;
    }
}

h3 {
    @include for-mobile {
        font-size: 2rem;
    }
    @include for-tablet {
        font-size: 2.2rem;
    }
    @include for-tablet-large {
        font-size: 2.4rem;
    }
    @include for-desktop {
        font-size: 2.6rem;
    }
}

h4 {
    @include for-mobile {
        font-size: 1.8rem;
    }
    @include for-tablet {
        font-size: 2rem;
    }
    @include for-tablet-large {
        font-size: 2.2rem;
    }
    @include for-desktop {
        font-size: 2.4rem;
    }
}

.choose-deal {
    @include for-mobile {
        font-size: 1.6rem;
    }
    @include for-tablet {
        font-size: 1.6rem;
    }
    @include for-tablet-large {
        font-size: 2rem;
    }
    @include for-desktop {
        font-size: 2.2rem;
    }
}

h5 {
    @include for-mobile {
        font-size: 1.6rem;
        font-family: "Santander Text Bold";
    }
    @include for-tablet {
        font-size: 1.8rem;
    }
    @include for-tablet-large {
        font-size: 2rem;
    }
    @include for-desktop {
        font-size: 2.2rem;
    }
}

.deals-section-header {
    font-family: 'Santander Text';
    display: inline-block;
    @include for-mobile {
        font-size: 1.8rem;
    }
    @include for-tablet {
        font-size: 2rem;
    }
    @include for-tablet-large {
        font-size: 2.2rem;
    }
    @include for-desktop {
        font-size: 2.4rem;
    }
}

.deals-large {
    @include for-mobile {
        font-size: 1.8rem;
    }
    @include for-tablet {
        font-size: 2rem;
    }
    @include for-tablet-large {
        font-size: 2.2rem;
    }
    @include for-desktop {
        font-size: 2.4rem;
    }
}
.small-values {
    @include for-mobile {
        font-size: 1.4rem;
    }
    @include for-tablet {
        font-size: 1.8rem;
    }
    @include for-tablet-large {
        font-size: 2.2rem;
    }
    @include for-desktop {
        font-size: 2.4rem;
    }
}

.body-large {
    @include for-mobile {
        font-size: 1.6rem;
    }
    @include for-tablet {
        font-size: 1.6rem;
    }
    @include for-tablet-large {
        font-size: 1.8rem;
    }
    @include for-desktop {
        font-size: 1.8rem;
    }
}

.body-small {
    @include for-mobile {
        font-size: 1.2rem;
    }
    @include for-tablet {
        font-size: 1.2rem;
    }
    @include for-tablet-large {
        font-size: 1.4rem;
    }
    @include for-desktop {
        font-size: 1.4rem;
    }
}

.toggle-text {
    @include for-mobile {
        font-size: 1.2rem;
    }
    @include for-tablet {
        font-size: 1.4rem;
    }
    @include for-tablet-large {
        font-size: 1.6rem;
    }
    @include for-desktop {
        font-size: 1.6rem;
    }
}

.subtitle-small {
    @include for-mobile {
        font-size: 1.4rem;
    }
    @include for-tablet {
        font-size: 1.4rem;
    }
    @include for-tablet-large {
        font-size: 1.6rem;
    }
    @include for-desktop {
        font-size: 1.6rem;
    }
}

.digit-large {
    @include for-mobile {
        font-size: 2rem;
    }
    @include for-tablet {
        font-size: 2.4rem;
    }
    @include for-tablet-large {
        font-size: 2.8rem;
    }
    @include for-desktop {
        font-size: 3.2rem;
    }
}

.digit-large-scale-2 {
    @include for-mobile {
        font-size: 2rem;
    }
    @include for-tablet {
        font-size: 2.2rem;
    }
    @include for-tablet-large {
        font-size: 2.4rem;
    }
    @include for-desktop {
        font-size: 2.6rem;
    }
}

.smaller-font{
    @include for-mobile {
        font-size: 1.4rem;
    }
    @include for-tablet {
        font-size: 1.6rem;
    }
    @include for-tablet-large {
        font-size: 1.8rem;
    }
    @include for-desktop {
        font-size: 2.0rem;
    }
}
.more-info-size{
    font-family: 'Santander Headline';
    @include for-mobile {
        font-size: 1.8rem;
    }
    @include for-tablet {
        font-size: 1.8rem;
    }
    @include for-tablet-large {
        font-size: 2.0rem;
    }
    @include for-desktop {
        font-size: 2.0rem;
    }
}

.font-size-input{
    font-family: 'Santander Headline';
    @include for-mobile {
        font-size: 1.6rem;
    }
    @include for-tablet {
        font-size: 1.8rem;
    }
    @include for-tablet-large {
        font-size: 2.0rem;
    }
    @include for-desktop {
        font-size: 2.2rem;
    }
}

.borrow-more-native-offer-title {
    font-family: 'Santander Text';
    @include for-mobile {
        font-size: 2.2rem;
    }
    @include for-tablet {
        font-size: 2.4rem;
    }
    @include for-tablet-large {
        font-size: 2.6rem;
    }
    @include for-desktop {
        font-size: 2.8rem;
    }
}

.borrow-more-native-subtitle {
    font-family: 'Santander Text';
    @include for-mobile {
        font-size: 2.0rem;
    }
    @include for-tablet {
        font-size: 2.2rem;
    }
    @include for-tablet-large {
        font-size: 2.4rem;
    }
    @include for-desktop {
        font-size: 2.6rem;
    }
}

.loan-selection{
    @include for-mobile {
        font-size: 1.6rem;
    }
    @include for-tablet {
        font-size: 1.8rem;
    }
    @include for-tablet-large {
        font-size: 1.8rem;
    }
    @include for-desktop {
        font-size: 1.8rem;
    }
}

.bold-text {
    font-family: "Santander Text Bold";
}

.value-link {
    font-weight: 600;
    color: $COLOR_SECONDARY_BLUE;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }

}





